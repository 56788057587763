<template>
    <span class="text-sm font-medium mr-2 px-1.5 py-0.5 rounded"
          :class="{
              'border-blue-100 border-2 bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300': colour === 'Default' || colour === 'Blue',
              'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300': colour === 'Dark',
              'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300': colour === 'Red',
              'bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-300': colour === 'Orange',
              'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300': colour === 'Green',
              'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300': colour === 'Yellow',
              'bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300': colour === 'Indigo',
              'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300': colour === 'Purple',
              'bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-300': colour === 'Pink',
              'bg-white': !props.filled,
          }">
        <slot>
            <span v-if="props.icon" class="pr-1">
                <i :class="props.iconPrefix + props.icon"></i>
            </span>
            {{ props.text }}
        </slot>
    </span>

</template>

<script setup lang="ts">
enum Colours {
    Default = 'Default',
    Blue = 'Blue',
    Dark = 'Dark',
    Red = 'Red',
    Orange = 'Orange',
    Green = 'Green',
    Yellow = 'Yellow',
    Indigo = 'Indigo',
    Purple = 'Purple',
    Pink = 'Pink',
    None = 'None'
}

const props = withDefaults(defineProps<{
    colour?: Colours;
    text?: string | null;
    icon?: string | null;
    iconPrefix?: string | null;
    filled: boolean;
}>(), {
    iconPrefix: 'fa fa-',
    colour: Colours.Default,
    text: null,
    filled: true
});
</script>

<style scoped>

</style>
