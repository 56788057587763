<template>
    <label
        v-if="label"
        :for="props.id"
        class="block text-sm font-medium"
        :class="{ 'text-gray-900': !disabled, 'text-gray-400': disabled, 'sr-only': hiddenLabel }"
    >
        <slot name="label"
        >{{ label }}<span v-if="required" class="text-red pl-0.5">*</span></slot
        >
    </label>
    <slot name="default" :bind="{ 'aria-describedby': hintId }"></slot>
    <p
        :id="hintId"
        v-if="props.hint"
        class="mb-4 text-sm text-gray-500 dark:text-gray-400"
    >
        <span v-html="hint"></span>
    </p>
    <p
        :id="errorId"
        v-if="error"
        class="mb-4 text-sm text-red-500 dark:text-gray-400 flex flex-row items-center"
    >
        <i class="fa fa-triangle-exclamation pr-1"></i>
        <span class="whitespace-pre" v-html="error"></span>
    </p>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
    defineProps<{
    id: string;
    label?: string | null;
    hiddenLabel?: boolean;
    hint?: string | null;
    error?: string | null;
    disabled?: boolean;
    required?: boolean;
  }>(),
    {
        label: null,
        hiddenLabel: false,
        hint: null,
        error: null,
        disabled: false,
        required: false,
    }
);

const hintId = computed<string>(() => props.id + '-hint');
const errorId = computed<string>(() => props.id + '-error');
</script>

<style scoped></style>
