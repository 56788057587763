import createSettings, {SettingType} from './core/settings';
import {allSettings} from './core/windowAccessor';
import {ref} from 'vue';
import axios from 'axios';

const settings = createSettings(axios, SettingType.Singleton);

const setting = ref<ESSettings>({});

settings.repository.addSettings(allSettings());

settings.repository.onSettingUpdated((key, value) => {
    setting.value[key] = value;
});

export {
    settings, setting
};
