<template>
    <v-field-wrapper
        :id="props.id"
        :disabled="disabled"
        :error="error"
        :hiddenLabel="hiddenLabel"
        :hint="hint"
        :label="label"
        :required="required"
    >
        <template #default="{ bind }">
            <div class="flex">
                <span
                    v-if="prefix"
                    class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    {{prefix}}
                </span>
                <input
                    :id="props.id"
                    v-model="dynamicValue"
                    :class="[
                        pale || props.disabled
                            ? 'text-gray-400  dark:text-gray-800'
                            : 'text-gray-900  dark:text-white',
                        prefix? 'rounded-none block flex-1' : '',
                        !prefix ? 'rounded-s-md' : '',
                        !postfix ? 'rounded-e-md' : '',
                    ]"
                    :disabled="props.disabled"
                    :name="props.id"
                    :placeholder="props.placeholder ?? undefined"
                    :required="props.required"
                    class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="text"
                    v-bind="bind"
                    @keyup="$emit('keyup', $event)"
                />
                <span
                    v-if="postfix"
                    class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-s-0 border-gray-300 border-s-0 rounded-e-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    {{postfix}}
                </span>
            </div>

        </template>
    </v-field-wrapper>
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import VFieldWrapper from '../FieldWrapper/VFieldWrapper.vue';

const props = withDefaults(defineProps<{
    id: string;
    label: string;
    hiddenLabel?: boolean;
    hint?: string | null;
    modelValue?: string | null;
    pale?: boolean | null;
    error?: string | null;
    disabled?: boolean;
    placeholder?: string | null;
    required?: boolean;
    prefix?: string | null;
    postfix?: string | null;
}>(), {
    pale: false,
    hiddenLabel: false,
    hint: null,
    modelValue: null,
    error: null,
    disabled: false,
    placeholder: null,
    required: false,
});

const dynamicValue = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: string): void;
}>();
</script>

<style scoped></style>
