<template>
    <div class="group relative z-10">
        <slot name="activator">

        </slot>

        <div class="z-100 relative">
            <slot v-if="active">
                <span
                    class="whitespace-pre pointer-events-none absolute w-max rounded bg-gray-900 px-2 py-1 text-sm font-medium text-gray-50 opacity-0 shadow transition-opacity group-hover:opacity-100"
                    :class="classes">
                    {{text}}
                </span>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">

import {computed} from 'vue';

const props = withDefaults(defineProps<{
    active: boolean;
    text: string|null;
    position?: string
}>(), {
    active: true,
    text: null,
    position: 'top' // top/bottom left/right/center
});

const classes = computed<string>(() => {
    let positions: string[] = props.position.split(' ');
    let yclass: string = '-top-7';
    let xclass: string = 'left-0';

    if(positions.includes('top')) {
        yclass = '-top-7';
    } else {
        yclass = '-bottom-7';
    }

    if(positions.includes('left')) {
        xclass = 'right-0';
    } else if(positions.includes('right')) {
        xclass = 'left-0';
    } else {
        xclass = 'left-1/2 -translate-x-1/2';
    }
    return yclass + ' ' + xclass;
});


</script>

<style scoped>

</style>
