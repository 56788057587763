<template>
    <button
        v-if="!loading"
        @click="$emit('click')"
        :class="{['bg-' + props.bgColour]: true}"
        class="rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <slot></slot>
    </button>
    <button
        v-else
        disabled
        :class="{['bg-' + props.bgColour]: true}"
        class="rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
    >
        <i class="fa fa-spinner fa-spin"></i>
    </button>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
    bgColour: string;
    loading: boolean;
}>(), {
    bgColour: 'white',
    loading: false,
});

defineEmits<{
    (event: 'click'): void
}>();
</script>

<style scoped>

</style>
