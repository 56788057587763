/**
 * Array With Values
 *
 * Function to check whether the parameter passed in maybeArray is an array
 * and that array has values.
 *
 * @param {*} maybeArray
 * @returns {boolean}
 */
const arrayWithValues = (maybeArray: any): maybeArray is any[] => {
    return Array.isArray(maybeArray) && maybeArray.length > 0;
};

export { arrayWithValues };
